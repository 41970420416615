import React from 'react';
import { Link } from 'gatsby'

/*
 * This component is a responsive navbar at the top of the main layout. 
 */

export default class NavComponent extends React.Component {

	render() {
		return (
			<navbar><nav>
      <div
        style={{
          background: `#1b1d29`,
        }}
      >
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,

          }}
        >
				<div className="navWide">
					<div className="wideDiv">
          <h1 style={{ margin: 0, display: 'inline-block', fontSize: '1.5rem',}}>
            <Link
              to="/"
              style={{
                color: `white`,
                textDecoration: `none`,
                padding: 0,
              }}
            >
              EFUPW
            </Link>
          </h1>
					  <div className="wideLinksDiv">
	            <a href="http://www.efupw.com/forums/" >Forums</a>
	            <a href="https://wiki.efupw.com/" >Wiki</a>
							<a href="http://nwn.efupw.com:10002//" >EFUSL</a>
	            <a href="https://discord.gg/htBk4fV" >Discord</a>
						</div>
					</div>
				</div>
				<div className="navNarrow">
					<i className="fa fa-bars fa-2x" onClick={this.burgerToggle}></i>
					<div className="narrowLinks">
            <a onClick={this.burgerToggle} href="http://www.efupw.com/forums/" >Forums</a>
            <a onClick={this.burgerToggle} href="https://wiki.efupw.com/" >Wiki</a>
						<a onClick={this.burgerToggle} href="nwn.efupw.com:10002//" >EFUSL</a>
            <a onClick={this.burgerToggle} href="https://discord.gg/htBk4fV" >Discord</a>
					</div>
				</div>
        </div>
        </div>
			</nav>
			</navbar>
		);
	}

	burgerToggle() {
		let linksEl = document.querySelector('.narrowLinks');
		if (linksEl.style.display === 'block') {
			linksEl.style.display = 'none';
		} else {
			linksEl.style.display = 'block';
		}
	}
};
