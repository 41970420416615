import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
const IndexPage = (props) => (
  <Layout isFrontpage={true}>
    <SEO title="Welcome to EFU: City of Rings" keywords={[`EFUPW`, `EFU`, `neverwinter`]} />
    <div style={{color: '#6f8a91'}}>
        <h2>"You wake up in a state of complete disorientation. You have been sleeping for a long time. A labyrinthine city comes into focus. This place... it is not the world you remember."</h2>
    </div>
    <h1>Welcome to EFU: City of Rings</h1>
    <p><strong>EFU: City of Rings</strong> is a world class roleplaying and storytelling experience.</p>
    <p>City of Rings is the fifth major chapter of a Neverwinter Nights persistent world that began in autumn 2005. It features exciting quests to fulfil, a vast world to explore, puzzles to solve, knowledge to uncover, factions to join, and deep systems and mechanics to master. City of Rings is richly developed and constantly updated.</p>
    <p>The world is brought to life by the players and dungeon masters in our community. We tell amazing stories together.</p>
    <p>Will you achieve fame and fortune in Ticker Square? Champion the endless contests of the nobility in the Peerage Ward? Or maybe you will find your way to the mythic King and receive your greatest wish? Wondrous things await you in the City of Rings.</p>

    <h2>Enter the City of Rings</h2>
    <p>Follow these steps to get started playing <strong>EFU: City of Rings</strong>.</p>
    <div class='row'>
      <div class='column'>
      <a className={'bannerButton'} href="https://store.steampowered.com/app/704450/Neverwinter_Nights_Enhanced_Edition/" style={{textDecoration:'none'}}>
      <Img fluid={props.data.imageOne.childImageSharp.fluid} />
      <div style={{
        padding: `0.75rem`,
        color: '#151821',
        display: 'inline-block',
        textAlign: 'center',
        minWidth: '100%',
      }}><h4 style={{margin: 0}}>INSTALL NWN:EE</h4></div></a><span style={{fontSize: '0.75rem', marginTop: '0.4rem'}}>Also available from <a href="https://www.beamdog.com/products/neverwinter-nights-enhanced-edition">Beamdog</a></span>

      </div>
      <div class='column'>
      <a className={'bannerButtonRed bannerButton'} href="https://discord.gg/htBk4fV" style={{textDecoration:'none'}}>
      <Img fluid={props.data.imageThree.childImageSharp.fluid} />
      <div  style={{
        padding: `0.75rem`,
        color: '#151821',
        textAlign: 'center',
      }}><h4 style={{margin: 0,}}>JOIN THE DISCORD</h4></div></a>
      <span style={{fontSize: '0.75rem', marginTop: '0.4rem'}}>Chat with us. :)</span>
      </div>
    </div>
    <p>When you complete these steps, launch Neverwinter Nights and find EFU in the multiplayer server list.</p>
    <p>Alternatively, connect directly to <span style={{color: '#6f8a91'}}>nwn.efupw.com:5121</span></p>

    <h2>Discover the secrets of the city</h2>
    <ul>
    <li>Join us on the <a href="http://www.efupw.com/forums/">forums</a> to check out in-character rumours, letters, and bulletins. Plus much more.</li>
    <li>Learn more about the setting and game mechanics on the <a href="https://wiki.efupw.com/">wiki</a>.</li>
    </ul>

  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "nwnee.png" }) {
      ...fluidImage
    }
    imageThree: file(relativePath: { eq: "discord.png" }) {
      ...fluidImage
    }
    imageFour: file(relativePath: { eq: "rings_logo.png" }) {
      ...fluidImage
    }
  }
`
