import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */

export default class Image extends React.Component {

  render(){
    const isFrontpage = this.props.isFrontpage;
      if(isFrontpage){
        return (
        <StaticQuery
          query={graphql`
            query {
              placeholderImage: file(relativePath: { eq: "rings.png" }) {
                childImageSharp {
                  fluid(maxWidth: 3000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          `}
          render={data => <Img style={{marginBottom: '5rem',}} fluid={data.placeholderImage.childImageSharp.fluid} />}
        />
      );
    }
      else return null;
  }



}

/*function Image (props){
const isFrontpage = props.isFrontpage;
  if(isFrontpage){
    <StaticQuery
      query={graphql`
        query {
          placeholderImage: file(relativePath: { eq: "rings.png" }) {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => <Img fluid={data.placeholderImage.childImageSharp.fluid} />}
    />
  }
  else return null;
  }

/*const Image = ({isFrontpage}) => (
if({isFrontpage}){
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "rings.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <Img fluid={data.placeholderImage.childImageSharp.fluid} />}
  />
}
)

Image.propTypes = {
  isFrontpage: PropTypes.boolean,
}

Image.defaultProps = {
  isFrontpage: false,
}

export default Image*/
