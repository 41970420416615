import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Image from './image'
import './layout.css'
import NavComponent from '../components/navbar'

export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 3000) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;

export default class Layout extends React.Component{

/*function SplashImage(props){
  if( props.isFrontpage){
  return <Img style={{marginBottom: '5rem',}} fluid={data.splashImage.childImageSharp.fluid} />;
  }
  else return null;
}*/

render(){

  return <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        splashImage: file(relativePath: { eq: "rings.png" }) {
          ...fluidImage
        }
      }
    `}
    render={data => (
      <>
        <NavComponent />
        <div
          style={{
            marginBottom: `1.45rem`,
          }}
        >
          <Image isFrontpage={this.props.isFrontpage}/>
        </div>
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
          }}
        >
          {this.props.children}
        </div>
        <footer>
        </footer>
      </>
    )}
  />;
}

}
